import Vue from 'vue'
import Vuex from 'vuex'
import {AccountGateway} from "@/gateway/AccountGateway";
import router from '../router'
import {PaymentGateway} from "@/gateway/PaymentGateway";
import {ScheduleGateway} from "@/gateway/ScheduleGateway";
import moment from "moment";
import {ShareGateway} from "@/gateway/ShareGateway";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profile: null,
    profileGateway: new AccountGateway(),
    paymentGateway: new PaymentGateway(),
    scheduleGateway: new ScheduleGateway(),
    shareTokenGateway: new ShareGateway(),
    teachers: [],
    token: null,
    schoolAccount : null,
    isLoginExists: false,
    lessons: [],
    timeNow: null,
    secondsProgressLesson : 0,
    payStatus : true,
    activeLesson : false,
    activeLessons : [],
    tariffs : [],
    shareData: {},
    isProfileUpdate: false,
    isFullscreenCamera: false,
    isFullscreenSchedule : false
  },
  mutations: {
    SET_PROFILE: (state, profile) => {
      state.profile = { ...profile }
    },

    SET_SCHOOL: (state, school) => {
      state.schoolAccount = {...school}
    },

    SET_LOGIN_EXISTS: (state,isExists) => {
      state.isLoginExists = isExists
    },

    SET_LESSONS: (state : any,{lessons,classId} : any) => {
      state.lessons[classId] = [...lessons]
      state.lessons = [...state.lessons]
    },

    SET_TIME_NOW: (state: any,time : any) => {
      state.timeNow = time
    },

    SET_PAY_STATUS: (state: any,status : any) => {
      state.payStatus = status
    },

    SET_TARIFFS: (state: any,tariffs : any) => {
      state.tariffs = [...tariffs]
    },

    SET_ACTIVE_LESSON: (state: any,activeLesson : any) => {
      state.activeLesson = activeLesson
    },

    SET_ACTIVE_LESSONS: (state: any,{activeLesson,classId} : any) => {
      state.activeLessons[classId] = activeLesson
      state.activeLessons = [...state.activeLessons]
    },

    SET_TEACHERS: (state: any, teachers) =>{
      state.teachers = [...teachers]
    },
    SET_SHARE_DATA: (state, sh) => {
      state.shareData = { ...sh }
    },
    SET_PROFILE_UPDATED:(state,updated) => {
      state.isProfileUpdate = updated
    },

    SET_FULLSCREEN_CAMERA: (state, isFullscreen) => {
      state.isFullscreenCamera = isFullscreen
    },
    SET_FULLSCREEN_SCHEDULE:(state,isFullscreen) => {
      state.isFullscreenSchedule = isFullscreen
    }
  },
  getters:{
    PROFILE: state => {
      return state.profile
    },
    SCHOOL: state => {
      return state.schoolAccount
    },
    IS_EXISTS_LOGIN : state => {
      return state.isLoginExists
    },
    LESSONS : (state) => (id : number) => {
      if (state.lessons[id] == null) return []
      return state.lessons[id]
    },
    TIME_NOW: state => {
      if (state.timeNow == null){
        return 0
      } else {
        //@ts-ignore
        return state.timeNow.toDate().getTime()
      }
    },
    PAY_STATUS: state => {
      return state.payStatus
    },
    ACTIVE_LESSON: state => {
      return state.activeLesson
    },

    ACTIVE_LESSONS: (state) => (id : number) => {
      if (state.activeLessons[id] == null) return false;
      return state.activeLessons[id]
    },

    TEACHERS: state => {
      return state.teachers
    },

    TARIFFS: state => {
      return state.tariffs
    },

    SHARE_DATA: (state) => {
      return state.shareData
    },
    IS_PROFILE_UPDATED: (state) => {
      return state.isProfileUpdate
    },
    IS_FULLSCREEN_CAMERA: (state) => {
      return state.isFullscreenCamera
    },
    IS_FULLSCREEN_SCHEDULE: (state) => {
      return state.isFullscreenSchedule
    },
  },
  actions: {
    GET_PROFILE: async (context:any) => {
      if (context.state.profile == null) {
        context.commit("SET_PROFILE_UPDATED",true)
        await context.dispatch('UPDATE_LOCAL_PROFILE')
        context.commit("SET_PROFILE_UPDATED",false)
      }
    },
    GET_SCHOOL: async (context:any,{id} : any) => {
      if (context.state.schoolAccount == null) {
        await context.dispatch('UPDATE_LOCAL_SCHOOL', {id : id})
      }
    },
    GET_TARIFFS: async (context:any) => {
      const tariffs = await context.state.profileGateway.getTariffs()
      context.commit("SET_TARIFFS",tariffs)
    },
    UPDATE_LOCAL_PROFILE: async (context:any) => {
      const profile = await context.state.profileGateway.getAccount()
      if (profile != null) {
        context.commit('SET_PROFILE', profile)
        context.commit("SET_PAY_STATUS",(moment(profile._expiredAt.date).toDate().getTime() > new Date().getTime()))

        //@ts-ignore
        window.ChatraSetup = {
          /* current user’s generated string */
          clientId: profile.chatraToken
        };


        // @ts-ignore
        Chatra('restart')
      } else {
        await router.push({name: "NewAuth"})
      }
    },
    UPDATE_LOCAL_SCHOOL: async ({state,commit} : any,{id} : any) => {
      console.log(state.profile)
      console.log("id",id)
      const school = await state.profileGateway.getSchoolById(id)

      for (let cam of school.shared){
        cam.blurPreferences = {
          x:300,
          y:300,
          w:100,
          h:100,
          cw:720,
          ch:560,
          isRound: true
        }
      }
      if (school != null){
        commit('SET_SCHOOL', school)
      }
    },
    UPDATE_PARENT_INFO: async({state,commit} : any,{dataObject} : any) => {
      const res = await state.profileGateway.updateMyUserDataNew(dataObject)
    },
    UPDATE_MY_PROFILE: async({state,commit} : any,{dataObject} : any) => {
      const res = await state.profileGateway.updateMyProfileData(dataObject)
    },

    UPDATE_TIME_NOW_FROM_SCHOOL: async({state,commit} : any,{schoolId} : any) => {
      const time = await state.educationObjectGateway.getSchoolTimeNow(schoolId);
      commit("SET_TIME_NOW",time)
    },

    UPDATE_LESSONS: async({state,commit} : any,{schoolId,classId,crc32} : any) => {
      const lessons = await state.scheduleGateway.getScheduleBySchoolClassId(schoolId,classId,crc32)
      if (lessons !== false){
        commit("SET_LESSONS",{lessons : lessons,classId : classId})
        const activeLesson =  state.activeLessons[classId]

        if (lessons.length > 0) {
          const lesson1 = lessons.filter((lesson: any) => {
            return lesson._availableStatus === 1;
          })[0]
          if (lesson1 != null && (!activeLesson || activeLesson._id !== lesson1._id || activeLesson.end.date !== lesson1.end.date)){
            commit("SET_ACTIVE_LESSONS",{activeLesson : lesson1,classId : classId})
          } else if (lesson1 == null && activeLesson){
            commit("SET_ACTIVE_LESSONS",{activeLesson : false,classId : classId})
          }
        } else {
          commit("SET_ACTIVE_LESSONS",{activeLesson : false,classId : classId})
        }
      }
    },

    CHECK_LOGIN_EXISTS: async({state,commit} : any,{login} : any) => {
      const res = await state.profileGateway.checkLoginExist(login)
      commit("SET_LOGIN_EXISTS",res)
    },

    PAY: async({state,commit} : any,{isEnableRecurrent,tariffId,periodId} : any) => {
      return state.paymentGateway.pay(isEnableRecurrent,tariffId,periodId);
    },

    DELETE_ACTIVE_CARD: async({state,commit} : any,{activeCard} : any) => {
      return state.paymentGateway.deleteActiveCard(activeCard)
    },


    CHANGE_RECURRENT: async({state,commit} : any,{isEnableRecurrent} : any) => {
      if (isEnableRecurrent){
        await state.paymentGateway.enableRecurrent();
      } else {
        await state.paymentGateway.disableRecurrent();
      }
    },

    DOWNLOAD_INVOICE: async({state,commit} : any,{id} : any) => {
      return state.paymentGateway.downloadInvoice(id)
    },

    AUTH_PARENT: async (context : any) => {

    },
    AUTH_ADMIN: async (context : any) => {

    },


    GET_TEACHERS: async ({state,commit} : any,{id} : any) => {
      const teachers = await state.profileGateway.getTeachers(id);

      commit("SET_TEACHERS",teachers)
    },

    CREATE_TEACHER:  async ({state,commit} : any,{educationObjectId,schoolClassId,data} : any) => {
      return await state.profileGateway.createTeacher(educationObjectId, schoolClassId, data)
    },

    EDIT_TEACHER:  async ({state,commit} : any,{teacherId,data} : any) => {
      return await state.profileGateway.editTeacher(teacherId, data)
    },


    UNION_ACCOUNTS: async ({state,commit} : any, {data} : any) => {
      return await state.profileGateway.unionAccounts(data)
    },


    RESET_STATE_TO_DEFAULT: ({state}) => {
      const state1 = {
        profile: null,
        profileGateway: new AccountGateway(),
        paymentGateway: new PaymentGateway(),
        scheduleGateway: new ScheduleGateway(),
        teachers: [],
        token: null,
        schoolAccount : state.schoolAccount,
        isLoginExists: false,
        lessons: state.lessons,
        timeNow: state.timeNow,
        secondsProgressLesson : state.secondsProgressLesson,
        payStatus : true,
        activeLesson : state.activeLesson
      }


      Object.assign(state, state1)

    },

    GET_SHARE_DATA_BY_TOKEN: async ({ state, commit }, { token }) => {
      const sh = await state.shareTokenGateway.getShareByToken(token)

      commit('SET_SHARE_DATA', sh)
    },


    GET_CODE: async ({state}) => {
      return await state.profileGateway.startSecurityCode();
    },


    VALIDATE_CODE: async ({state},{code}) => {
      return await state.profileGateway.validateSecurityCode(code);
    },


    SEND_SMS_CODE: async ({state}) => {
      return await state.profileGateway.sendUntrustedSmsCode();
    },

    EXIT_FROM_ACCOUNT: async ({state}) => {
      const res = await state.profileGateway.exitUser();
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")

      //@ts-ignore
      window.ChatraSetup = {
        /* current user’s generated string */
        clientId: ""
      };


      // @ts-ignore
      Chatra('restart')
      return res
    },


    changeFullScreen({state,commit},{isFullscreen,isSchedule,scheduleInfo}) {
      if (isSchedule){
        commit("SET_FULLSCREEN_SCHEDULE",scheduleInfo)
      } else {
        commit("SET_FULLSCREEN_CAMERA",!!isFullscreen)
      }
      return !!isFullscreen

    },
  },
  modules: {

  }
})
