import {BaseGateway} from "@/gateway/BaseGateway";
import {Schedule} from "@/entity/Schedule";
import {plainToClass} from "class-transformer";

export class ScheduleGateway extends BaseGateway{

    public async getScheduleBySchoolClassId(educationObjectId: number,schoolClassId: number,crc32 : number) : Promise<Schedule[] | boolean>{
        const res = await this.httpClient.get(`education/${educationObjectId}/schoolClass/${schoolClassId}/lessons/${crc32}`);


        if (res.status === 204) return false;
        return plainToClass(Schedule,res.data);
    }

    public async createScheduleForClass(educationObjectId: number,schoolClassId: number,scheduleName: string,cameraId : number,dayName: string,duration: number | null){
        const res = await this.httpClient.post(`education/${educationObjectId}/class/${schoolClassId}/lesson`,{
            scheduleName: scheduleName,
            cameraId: cameraId,
            dayName: dayName,
            duration: duration
        })

        if (res.status === 200){
            return res.data
        } else {
            return false
        }
    }

    public async changeScheduleForClass(educationObjectId: number,schoolClassId: number,scheduleName: string,cameraId : number,dayName: string,lessonId: number,duration : number | null){
        const res = await this.httpClient.put(`education/${educationObjectId}/class/${schoolClassId}/lesson/${lessonId}`,{
            scheduleName: scheduleName,
            cameraId: cameraId,
            duration : duration
        })
        if (res.status === 200){
            return res.data
        } else {
            return false
        }
    }

    public async deleteScheduleForClass(educationObjectId: number,schoolClassId: number,lessonId: number){
        const res = await this.httpClient.delete(`education/${educationObjectId}/class/${schoolClassId}/lesson/${lessonId}`);

        if (res.status === 200){
            return res.data
        } else {
            return false
        }
    }

    public async checkIsNewSchedule(educationObjectId: number,schoolClassId: number,crc32: number) : Promise<boolean>{
        const res = await this.httpClient.get(`education/${educationObjectId}/schoolClass/${schoolClassId}/lessons/${crc32}`);

        return res.status === 204
    }

}
