import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import Token from "@/entity/Token";
import {plainToClass} from "class-transformer";
import {vueApp} from "@/main";



export default class HttpClient {
    // private static readonly BASE_URL = "http://192.168.0.188:8085/";
    // private static readonly BASE_URL = "https://test-api.esova.ru/";
    // private static readonly BASE_URL = "https://api.esova.ru/";
    private static readonly BASE_URL = "https://test-api.esova.ru";
    private static readonly API_VERSION = "";
    private static _httpClient : AxiosInstance;
    static isUpdate = false;

    public static getHttpClient(){
        if (this._httpClient == null){
            this._httpClient = this.make();
        }
        return this._httpClient;
    }

    private static make() : AxiosInstance{
        let httpClient = axios.create({
            baseURL: `${this.BASE_URL}/${this.API_VERSION}/`
        })

        httpClient.interceptors.request.use(this.requestInterceptor,function (error) {
            return Promise.reject(error)
        });

        httpClient.interceptors.response.use(this.responseInterceptor,this.authenticatorInterceptor);

        return httpClient;
    }

    private static requestInterceptor(config : AxiosRequestConfig) : AxiosRequestConfig{
        if (config.headers.common["Authorization"] == null){
            config.headers.common['Authorization'] = `Bearer ${(document.location.href.indexOf("admin") >= 0) ? localStorage.getItem('adminAccessToken') : localStorage.getItem('accessToken')}`;
        }

        if (config.headers["Content-Type"] == null){
            config.headers.common['Content-Type'] = "application/json"
        }
        return config;
    }

    private static authenticatorInterceptor(error : any) {

        if (error.response == null) throw error;
        if (error.response.status === 401){
            const refreshToken = (document.location.href.indexOf("admin") >= 0) ? localStorage.getItem("adminRefreshToken") : localStorage.getItem("refreshToken");
            if (!HttpClient.isUpdate){
                HttpClient.isUpdate = true;

                return HttpClient.getHttpClient().put(`auth/${refreshToken}`,null).then((response) =>{
                    if (response == null){
                        // document.location.href = 'auth'
                    }

                    if (vueApp.$router.currentRoute.name !== 'NewAuth'){
                        if (response.status === 400){
                            document.location.href = '/auth'
                        }

                        const token : Token = plainToClass(Token,response.data);

                        if (document.location.href.indexOf("admin") >= 0){
                            localStorage.setItem('adminAccessToken', token.accessToken);
                            localStorage.setItem('adminRefreshToken', token.refreshToken);
                        } else {
                            localStorage.setItem('accessToken', token.accessToken);
                            localStorage.setItem('refreshToken', token.refreshToken);
                        }


                        error.config.headers['Authorization'] = `Bearer ${token.accessToken}`;
                        HttpClient.isUpdate = false;
                        return HttpClient.getHttpClient().request(error.config);
                    }
                })
            }
        } else if (error.response.status === 403){
            // vueApp.$router?.push({name : 'SecurityView'})
            document.location.href = '/security'
        } else {
            return error.response;
        }
    }

    private static responseInterceptor(response : AxiosResponse) : AxiosResponse{
        return response;
    }

    static sleep(milliseconds: number) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }





}
